import { graphql, StaticQuery } from "gatsby";
import { GatsbyImageProps } from "gatsby-image";
import React, { useEffect, useMemo, useState } from "react";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import Media, { IMediaProps } from "../../comps/media";
import TileGrid, { TileData } from "../../comps/tile-grid";
import { IGatsbyFile, IGatsbyQueryResultProps, ILayout, IMedia } from "../../types";
import { tryget } from "../../utils";
import Header from "./header";
import './indexLayout.scss';


type HomeGridProps = IGatsbyQueryResultProps<{
  allContentYaml : {medias: IMedia[], layouts: ILayout[]}
  gifs           : Pick<IGatsbyFile, 'publicURL'|'base'>,
  imagesFluid    : IGatsbyFile & {childImageSharp: GatsbyImageProps},
  overlay        : any,
  children       : any,
}>['data']


const HomeGrid = (props: HomeGridProps) => {
  // console.log(`indexlayoutprops`, props)

  const yamlContent = props.allContentYaml.nodes[0]
  const medias      = yamlContent.medias
  const layouts     = yamlContent.layouts || []
  const gifs        = props.gifs.nodes
  const images      = props.imagesFluid.nodes

  const layoutsSorted = useMemo(() => (
    layouts ? layouts.sort( (a, b) => a.minWidth < b.minWidth ? 1 : -1) : []
  ), [layouts])

  const [currentLayout, setCurrentLayout] = useState<ILayout|undefined>(layouts[0] || undefined)  
  const [tileGridWidthPx, setTileGridWidthPx] = useState(undefined as number|undefined);

  // console.log(`layouts`, layouts)
  
  const findFittingLayout = useMemo(() => (windowWidth: number) => {
    // console.log(`findFittingLayout`)
    let layout: ILayout|undefined;
    for(let l of layoutsSorted) {
      if (l.minWidth <= windowWidth) {
        layout = l
        break;
      }
    }
    return layout ? layout : layouts.length ? layouts[layouts.length-1] : undefined
  }, [layoutsSorted])

  const tileGridWidth = useMemo( () => {
    const layoutwidth = currentLayout ? currentLayout.minWidth : 0
    return layoutwidth;
  }, [currentLayout])

  // const tileGridWidthPx = useMemo( () => {
  //   return currentLayout && currentLayout.fillWindow ? windowWidth : undefined
  // }, [currentLayout, windowWidth]);

  // listening for window size change:
  useEffect( () => {
    if (!window) return;
    setCurrentLayout(findFittingLayout(window.innerWidth))
    
    const handleWindowResize = (layout?: ILayout) => {
      const fittingLayout = findFittingLayout(window.innerWidth);
      setCurrentLayout(fittingLayout)
      setTileGridWidthPx(fittingLayout && fittingLayout.fillWindow ? window.innerWidth : undefined)
    }
    const s =
      fromEvent(window, 'resize').pipe(debounceTime(250)).subscribe(event => handleWindowResize())
    
    handleWindowResize();
    return () => s.unsubscribe();
  }, [findFittingLayout])

  // gets TileDatas for the TilGrid component:
  const tiles = useMemo( () => {
    if (!currentLayout || !currentLayout.tiles) return []

    const tiles: TileData[] = [];
    currentLayout.tiles.forEach( t => {
      const {name, size, thumb} = t
      const media = medias.find( m => m.name === name)
      if (media) {
        // check if the layoutTile provides a thumb for overriding
        // if (thumb) { media.thumb = thumb }
        const mediaProps: IMediaProps = {media}
        mediaProps.imgSrc = tryget(() => gifs.find( gif => gif.base === (thumb || mediaProps.media.thumb))!.publicURL)
        if (!mediaProps.imgSrc )
          mediaProps.imgProps = tryget(() => images.find( image => image.base === (thumb || mediaProps.media.thumb))!.childImageSharp)

        // console.log(`mediaprops`, mediaProps)
        tiles.push({
          size: size === 's' ? 1 : size === 'm' ? 2 : 3,
          content: <Media {...mediaProps} />
        })
      }
    })
    // console.log(`tiles`, tiles)
    return tiles;
    
  }, [currentLayout, props])


  
  return (<>
    <div className="indexlayout">
      <Header></Header>
      <div className="indexlayout--content">
        { currentLayout &&
          <TileGrid
            width={tileGridWidth}
            tiles={tiles}
            widthPx={tileGridWidthPx}
          />
        }
      </div>
        { props.children && <div className="indexlayout--overlay">{ props.children}</div> }
  
    </div>
  </>)
}

interface Props {
  overlay?: any,
  children?: any,
}
const IndexLayout = (props: Props) => {    
  return (
    <StaticQuery
      query={query}
      render={ (result) => <HomeGrid {...result} overlay={props.overlay}>{props.children}</HomeGrid> }
    />
  )
}

export default IndexLayout

const query = graphql`
  query
  HomePageQuery {
    allContentYaml {
      nodes {
        medias { name thumb video}
        layouts {
          minWidth fillWindow tiles { name size }
        }
      }
    }
    gifs:allFile(filter: {extension: {eq: "gif"}}) {
      nodes { publicURL base }
    }
    imagesFluid:allFile(filter: {extension: {in: ["jpg", "png", "gif"]}}) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            aspectRatio
            src
            srcSet
            sizes
            originalImg
            originalName
            presentationWidth
            presentationHeight
          }
        }
      }
    }
  }
`

//...GatsbyImageSharpFluid_noBase64