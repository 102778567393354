import { Link } from 'gatsby';
import React, { useState } from 'react';
import iconClose from '../../icons/baseline-close-24px.svg';
import iconBurger from '../../icons/baseline-menu-24px.svg';
import Foldmenu from "../foldmenu/foldmenu";
import ImgButton from '../img-button/img-button';
import './header.scss';
import logo from './sr_logo.jpg';

interface ILink {
  // use 'href' instead of 'to' for external destinations:
  label: string; to?: string; href?: string;
}

const linksLeft: ILink[] = [
  { label: 'kunden login', href: 'http://cloud.schallundrauch.tv' },
  { label: 'kontakt', to: '/kontakt/' },
]

const linksRight: ILink[] = [
  { label: 'about', to: '/about/'},
  { label: 'impressum', to: '/impressum/'},
]

const links = [...linksLeft, ...linksRight]


const Header = () => {

  // foldmenu:
  const [isMenuOpen, setIsMenuOpen] = useState(false);


  return (<>
    <div className="header">
      <div className="header--segment">
        { linksLeft.map( link => (
          link.to
            ? <Link to={link.to} className="header--headerlink">{link.label}</Link>
            : <a href={link.href} className="header--headerlink">{link.label}</a>
        ))}
      </div>
      <div className="header--segment header--center">
        <Link to="/" className="header--logo">
          <img className="header--logo" src={logo} alt="Schal & Rauch Logo"/>
        </Link>
      </div>
      <div className="header--segment">
        { linksRight.map( (link, i) => (
            link.to
              ? <Link to={link.to} key={i} className="header--headerlink">{link.label}</Link>
              : <a href={link.href} key={i} className="header--headerlink">{link.label}</a>
        ))}
      </div>

      {isMenuOpen
        ? (
          <ImgButton
            src={iconClose}
            className="header--menubutton"
            onClick={() => setIsMenuOpen(false) }
          />
        )
        : (
          <ImgButton
            src={iconBurger}
            className="header--menubutton"
            onClick={() => setIsMenuOpen(true) }
          />
        )
      }
    </div>
    <Foldmenu isOpen={isMenuOpen} className="header--foldmenu">
      { links.map( (link, i) => (
          link.to
            ? <Link to={link.to} key={i} onClick={() => setIsMenuOpen(false)} className="header--headerlink">{link.label}</Link>
            : <a href={link.href} key={i} onClick={() => setIsMenuOpen(false)} className="header--headerlink">{link.label}</a>
        ))}
    </Foldmenu>
  </>)
}

export default Header;