// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-media-page-tsx": () => import("/Users/alexanderfeichter 1/Documents/GIT_sr_website/schall_rauch_site/src/templates/media-page.tsx" /* webpackChunkName: "component---src-templates-media-page-tsx" */),
  "component---src-templates-markdown-tsx": () => import("/Users/alexanderfeichter 1/Documents/GIT_sr_website/schall_rauch_site/src/templates/markdown.tsx" /* webpackChunkName: "component---src-templates-markdown-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/alexanderfeichter 1/Documents/GIT_sr_website/schall_rauch_site/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/alexanderfeichter 1/Documents/GIT_sr_website/schall_rauch_site/.cache/data.json")

