import React, { CSSProperties } from 'react';
import './tile.scss';

interface Props {
  width: number;
  height: number;
  x: number;
  y: number;
  children?: any;
}
const Tile = (props: Props) => {
  const style: CSSProperties = {
    position: 'absolute',
    width: `${props.width}px`,
    height: `${props.height}px`,
    left: `${props.x}px`,
    top: `${props.y}px`,
  }
 return (
    <div className="tile" style={style}>
      {props.children}
    </div>
 )
}
export default Tile;