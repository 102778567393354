export interface Tile {
  width: number,
  height: number,
}

function newRow(width: number) {
   const row: number[] = [] 
   for(let i = 0; i < width; i++) {
     row.push(-1)
   }
   return row
}

function hasSpace(tile: Tile, row: number[], startIndex: number) {
  for (let i = startIndex; i < startIndex + tile.width; i++) {
    if (i >= row.length || row[i] > -1) {
      return false;
    }
  }
  return true;
}

function drawRect( content: number, grid: number[][], x: number, y: number, width: number, height: number) {
  for( let i = y; i < y + height; i++) {
    for( let j = x; j < x + width; j++) {
      if (i >= grid.length || j >= grid[i].length) 
        return grid;
      grid[i][j] = content
    }
  }
  return grid;
}

export function align(gridWidth: number, tiles: Tile[]){
  const grid: number[][] = [newRow(gridWidth)];
  
  return tiles.map( (tile, tileIndex) => {
    // console.log(`Tile`, tileIndex, tile)
    // check if tile.width is bigger than the grid:
    if ( tile.width > gridWidth) {
      return [0,0]
    }

    let tileColIndex = -1, tileRowIndex = -1;
    let rowIndex = 0
    // repeat until a space was found:
    while( tileColIndex < 0 ) {
      // console.log(`testing new row`)
      // make sure there are enough rows for tile to begin with:
      while(rowIndex + tile.height > grid.length) {
        grid.push(newRow(gridWidth));
      }
      const row = grid[rowIndex];
      // console.log(`row count:`, grid.length)
      // go the each col and check if tile would fit:
      row.some( (col, colIndex) => {
        // check if row even has enough length
        if ( colIndex + tile.width > row.length )
          return true;
        // check if all following rows could contain the tiles width:
        let fits = true
        for(let j = rowIndex; j < rowIndex + tile.height; j++) {
          // console.log(`isFitting(${tile.width}, ${grid[j]}, ${colIndex})`, hasSpace(tile, grid[j], colIndex))
          if (!hasSpace(tile, grid[j], colIndex)) {
            fits = false;
            break;
          } 
        }
        // console.log(`tileColIndex`, tileColIndex)
        if (fits) {
          tileColIndex = colIndex;
          tileRowIndex = rowIndex;
          drawRect(tileIndex, grid, colIndex, rowIndex, tile.width, tile.height)
        }
        return fits
      })
      rowIndex++
    }
    // console.log(`grid`, JSON.stringify(grid))
    return [tileColIndex, tileRowIndex]
  })
}

