import React, { useMemo } from "react";
import Tile from "../comps/Tile";
import { align } from "../tile-grid";
import './tile-grid.scss';

export interface TileData {
  size: number;
  content?: JSX.Element
}

const defaultProps = {
  width: 864,
  unit: 96,
  widthPx: undefined  as number|undefined
}
type DefaultProps = typeof defaultProps;

interface Props extends Partial<DefaultProps> {
  tiles: TileData[]
}


const TileGrid = (props: Props) => {
  let {tiles, width, unit, widthPx} = {...defaultProps, ...props}
  if (width <= 0) width = defaultProps.width
  // console.log(`width`, width)
  // console.log(`got tiles`, props.tiles)

  const gridWidth = useMemo( () => Math.floor(width / unit), [width, unit])

  const calculatedUnit = useMemo(() => {
    return widthPx ? widthPx / gridWidth : unit
  }, [gridWidth, widthPx])

  // console.log(`calculated`, calculatedUnit)

  const containerWidthPx = useMemo( () => gridWidth * unit, [gridWidth])

  const positionedTiles = useMemo( () => {
    // console.log(`gridWidth`, gridWidth)
    const positions = align(gridWidth, tiles.map( tile => {
      return {
        width: tile.size, height: tile.size,
      }
    }))
    return tiles.map( (tile, i) => ({
      width: tile.size * calculatedUnit,
      height: (tile.size * calculatedUnit) / 1.618,
      x: positions[i][0] * calculatedUnit,
      y: positions[i][1] * calculatedUnit / 1.618,
      content: tile.content,
    }))
  }, [calculatedUnit, gridWidth, tiles])

  return (
    <div className="tile-grid" style={{width: `${widthPx || containerWidthPx}px`}}>
      { positionedTiles.map( (tile, i) => (
        <Tile key={i} {...tile}>
          {tile.content}
        </Tile>
      ))}
    </div>
  )
}

export default TileGrid