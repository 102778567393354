import React from 'react';
import { Link } from 'gatsby';
import './img-button.scss';
import classnames from 'classnames';

interface Props {
  src: string;
  href?: string; 
  className?: string;
  onClick?: () => void;
}
const ImgButton = (p: Props) => (
  <Link
    onClick={p.onClick}
    to={p.href || ''}
    className={classnames('img-button', p.className)}
  >
    <div className="img-button--container">
      <img src={p.src}/>
    </div>
  </Link>
)

export default ImgButton;