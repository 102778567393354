import React, { CSSProperties } from 'react';
import './foldmenu.scss';
import classnames from 'classnames';

interface Props {
  isOpen: boolean,
  onClick?: () => void
  children?: any;
  className?: string;
}
const Foldmenu = (p: Props) => {
  const style: CSSProperties = {
    transform: `translateX(${p.isOpen ? '0' : '100vw'})`
  }

  return (
    <div className={classnames(`foldmenu`, p.className)} style={style}>
      <div className="foldmenu--content">
        {p.children}
      </div>
      {/* <ImgButton onClick={p.onClick} src={iconClose} className="foldmenu--closebutton"/> */}
    </div>
  )
}

export default Foldmenu;