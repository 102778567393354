export const tuple = <T extends unknown[]>(...args: T) => args

// eslint-disable-next-line arrow-parens
export const tryget = <T>(exp: () => T, d: T|undefined = undefined) => {
  try {
    const val = exp()
    if (val != null) {
      return val
    }
  } catch { /* noop */ }
  return d
}