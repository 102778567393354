import React from 'react';
import { IMedia } from '../types';
import './media.scss'
import Img, { GatsbyImageProps } from 'gatsby-image';
import { Link } from 'gatsby';

export interface IMediaProps {
  media: IMedia;
  imgProps?: GatsbyImageProps
  imgSrc?: string
}

const Media = (props: IMediaProps) => (
  <div className="media">
    <Link to={`/media/${props.media.name}`}>
    {
      props.imgProps ?
        <Img className="bg" {...props.imgProps} style={{height: '100%'}} fadeIn={false}></Img>
      : props.imgSrc ?
        <img className="bg" src={props.imgSrc} alt=""/>
      : undefined
    }
    </Link>
  </div>
)

export default Media;